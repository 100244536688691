import { render, staticRenderFns } from "./AccountsPayableHistory.vue?vue&type=template&id=1048fff2&scoped=true"
import script from "./AccountsPayableHistory.vue?vue&type=script&lang=js"
export * from "./AccountsPayableHistory.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1048fff2",
  null
  
)

export default component.exports