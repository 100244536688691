<template>
    <v-form v-model="valid">
        <block v-if="loading"></block>
        <v-card>
            <v-card-title>
                <h3>Realizar abono a crédito</h3>
            </v-card-title>
            <v-card-text>
                <p>Para realizar abonos a las ordenes de compra, modificar la celda (Abono a realizar)</p>
                <DxDataGrid ref="dxDataGrid" :dataSource="purchasePendingToPay" :showBorders="true" :scrolling="{ useNative: true }" :column-auto-width="true" 
                @cellClick="handleCellClick">
                    <DxEditing :allow-updating="true" :select-text-on-edit-start="true" start-edit-action="click"
                    mode="cell" />
                    <DxColumn dataField="ID_PurchaseOrder" :allow-editing="false" caption="ID de Orden" width="175" />
                    <DxColumn dataField="ID_PurchaseLegalInvoiceNumber" :allow-editing="false" caption="Correlativo o Código de Generación" width="320" />
                    <DxColumn cell-template="canceled" :allow-editing="false" caption="Abonado" />
                    <template #canceled="{ data }">
                        {{ data.data.Canceled.toFixed(2) }}
                    </template>
                    <DxColumn cell-template="total-pay" :allow-editing="false" caption="Adeudado" />
                    <template #total-pay="{ data }">
                        {{ data.data.PurchaseOrderTotal.toFixed(2) }}
                    </template>
                    <DxColumn cell-template="pending-pay" :allow-editing="false" caption="Pendiente"/>
                    <template #pending-pay="{ data }">
                        {{ data.data.PendingToPay.toFixed(2) }}
                    </template>

                    <DxColumn data-field="PaymentToBeMade" caption="Abono a realizar" data-type="number">
                        <DxFormat typed="fixedPoint" :precision="2"/>
                        <DxPatternRule :pattern="paymentPattern"
                            message="El abono no puede tener números negativos" />
                        <DxAsyncRule :validation-callback="handlePayment"
                            message="La cantidad del abono no puede exceder al pendiente de la orden de compra" />
                    </DxColumn>
                </DxDataGrid>
                <v-row class="mt-2">
                    <v-col cols="12" md="3" xs="12">
                        <v-text-field
                        v-model="form.TotalPayment"
                        label="Total abonado"
                        outlined
                        dense
                        readonly
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3" xs="12">
                        <v-select v-model="form.PaymentType" outlined dense attach :items="paymentTypes" required 
                        :rules="[
                            (v) => !!v || 'El método de pago es requerido',
                        ]"
                            item-text="Valores" label="Método de pago" class="fast-ease-in-transitioned-element">
                        </v-select>
                    </v-col>
                    <v-col cols="12" md="3" xs="12" v-if="form.PaymentType != 'Billetes y monedas' && form.PaymentType != undefined">
                        <v-text-field outlined dense v-model="form.NReference" label="N. Referencia" required 
                        :rules="[
                            (v) => !!v || 'El número de referencia es requerido',
                        ]">
                        </v-text-field>
                        </v-col>
                    <v-col col="12" md="3" xs="12">
                        <date-picker label="Fecha de abono" @dateSelected="(param) => (form.PaymentDate = param)" dense
                        :disbaled="true"
                        >
                        </date-picker>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col col="12" md="12">
                        <quill-editor :content="form.Note" :options="quillOptions" @change="onEditorChange($event)"></quill-editor>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-btn @click="closeModal" class="btndisable" >Cancelar</v-btn>
                <v-spacer></v-spacer>
                <v-btn :disabled="!valid" @click="addPaymentToAccountPayable()" class="btnsave">Realizar abono</v-btn>
            </v-card-actions>
        </v-card>

        <alerts v-if="alert.show" v-on:close_alert="closeAlert" v-on:accept_alert="acceptAlert" :properties="alert">
        </alerts>
    </v-form>
</template>

<script>
    import { mapGetters } from "vuex";
    import DatePicker from "../../../components/DatePicker";
    import {DxColumn, DxDataGrid, DxFormat, DxEditing, DxPatternRule, DxAsyncRule} from 'devextreme-vue/data-grid';
    import { quillConfig } from "../../../helpers/quillconfig";
    import Alerts from '@/components/Alerts.vue'
    import Block from "@/components/Block";
    export default {
        name: "AccountsPayableForm",
        components: {
            DxColumn, 
            DxDataGrid,
            DxFormat,
            DxEditing,
            DxPatternRule,
            DatePicker,
            DxAsyncRule,
            Alerts,
            Block
        },
        props: ['win', 'Provider'],
        data() {
            return {
                alert: {
                    type: "success",
                    show: false,
                    header: "",
                    body: "",
                },
                form: {
                    ID_Business: this.Provider.ID_Business,
                    ID_Branch: this.Provider.ID_Branch,
                    ID_Provider: this.Provider.ID_Provider,
                    TotalPayment: 0
                },
                purchasePendingToPay: [],
                quillOptions: quillConfig,
                paymentPattern: /^(0*[0-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[0-9][0-9]*)$/i,
                originalCellValue: 0,
                valid: false,
                loading: false
            }
        },
        watch: {
            purchasePendingToPay: {
                handler: function (data) {
                    this.form.TotalPayment = 0
                    for(let i = 0; i < data.length; i++){
                        this.form.TotalPayment += data[i].PaymentToBeMade
                    }
                },
                deep: true
            }
        },  
        computed:{
            ...mapGetters(['getCatalogMH']),
            paymentTypes() {
              return this.getCatalogMH('FormaPago');
            },
        },
        mounted(){
            this.getPurchasePendingToPay()
        },
        methods: {
            showAlert(type, header, body, options = null) {
                type = type == null ? "danger" : type;
                this.alert.type = type;
                this.alert.header = header;
                this.alert.body = body;
                this.alert.show = true;
                this.alert.options = options != null ? options : null;
            },

            closeAlert(){
                this.alert.show = false;
            },

            closeModal() {
                this.$emit("close");
            },

            async getPurchasePendingToPay() {
                this.$API.providers.getPurchasePendingToPay({ "ID_Provider": this.Provider.ID_Provider })
                .then(response => {
                    this.purchasePendingToPay = response.map(item => ({...item, PaymentToBeMade: 0}))
                })
            },

            async handlePayment(params){
                const { data: { PendingToPay, PaymentToBeMade } } = params
                
                if(PaymentToBeMade > PendingToPay) {
                    return false
                }
                else{
                    return true
                }
            },

            handleCellClick(e){
                const val = e.data.PaymentToBeMade
                this.originalCellValue = val
            },
            
            async addPaymentToAccountPayable(){
                this.loading = true
                // console.log( 'reached', this.form );
                if(this.form.TotalPayment == 0){
                    this.showAlert( "warning", "Error", 'No puede realizar un pago con monto 0');
                    this.loading = false;
                    return;
                }
                this.$refs.dxDataGrid.instance.saveEditData()
                .then(() => {
                    
                    this.$API.providers.addPaymentToAccountPayable({ "purchaseCredits": this.purchasePendingToPay, "payment": this.form })
                    .then(async (response) => {
                        this.loading = false
                        await this.getPurchasePendingToPay()
                        this.form.Note = ""
                        this.showAlert("success", "Éxito", response.message)
                    })
                    .catch((err) => {
                        this.loading = false
                        this.showAlert("danger", "Error", err)
                        //this.showAlert("danger", "Error", "Ocurrio un problema al realizar el abono, por favor intentarlo nuevamente")
                    })
                })
                .catch(() => {
                    this.loading = false
                    this.showAlert("danger", "Error", "Ocurrio un problema en actualizar la información de la tabla, por favor intentarlo nuevamente.")
                })
            },
        },

    }
</script>

<style scoped>

.btndelete {
  background-color: #E3191E !important;
  color: white;
}

.btn-add {
  background-color: #3498DB !important;
  color: white;
}

.btnsave {
  background-color: #00A178 !important;
  color: white;
}

.btndisable {
  background-color: #41464c !important;
  color: white;
}

</style>