<template>
    <div>
        <DxDataGrid 
        :dataSource="AccountData"
        :scrolling="{ useNative: true }"
        :showBorders="true"
        :column-auto-width="true"
        @exporting="onExporting"
        >
            <DxExport :enabled="true" />
            <DxColumn cell-template="show-template" :width="100"/>
            <DxColumn dataField="ID_Provider" caption="ID de Proveedor" />
            <DxColumn dataField="ProviderName" caption="Nombre"/>
            <DxColumn dataField="TotalCanceledShow" caption="Abonado" />
            <DxColumn dataField="TotalDebtShow" caption="Adeudado" />
            
           <!--  <DxColumn cell-template="total-canceled" caption="Abonado" />
            <template #total-canceled="{ data }">
                <div>
                    {{ data.data.TotalCanceled.toFixed(2) }}
                </div>
            </template>
            <DxColumn cell-template="total-debt" caption="Adeudado" />
            <template #total-debt="{ data }">
                <div>
                    {{ data.data.TotalDebt.toFixed(2) }}
                </div>
            </template> -->

            <template #show-template="{data}">
                <div>
                    <a v-if="$store.getters['rolesUser/getCustomRole'](126)" class="mx-1" @click.prevent.stop=" addPayment = true; AccountDetails = data.data; key++" title="Realizar abonos a crédito">
                        <v-icon>mdi-cash-plus</v-icon>
                    </a>
                    <a v-if="$store.getters['rolesUser/getCustomRole'](127)" class="mx-1" @click.prevent.stop=" shwoHistory = true; AccountDetails = data.data; key++" title="Historial de abonos y ordenes de compra a crédito">
                        <v-icon>mdi-account-cash</v-icon>
                    </a>
                </div>
            </template>

            
            <DxPager :showPageSizeSelector="true"  />
            <DxPaging :defaultPageSize="5" />
        </DxDatagrid>

        <v-dialog v-model="addPayment" width="90%">
            <AccountsPayableForm
                :key="key"
                :win="win"
                :Provider="AccountDetails"
                v-on:close="reloadData"
            />
        </v-dialog>
        <v-dialog v-model="shwoHistory" width="90%" ref="arhModal" @hidden="handleModalHiddenHistory">
            <AccountsPayableHistory
                :key="key"
                :win="win"
                :Provider="AccountDetails"
            />
        </v-dialog>


    </div>
</template>

<script>
    import AccountsPayableForm from './AccountsPayableForm.vue';
    import AccountsPayableHistory from './AccountsPayableHistory.vue';
    
    //import {mapState} from 'vuex';
    import {DxDataGrid, DxPager, DxPaging, DxColumn, DxExport} from 'devextreme-vue/data-grid';
    export default {
        name: "AccountsPayableList",
        components: {
            DxDataGrid,
            DxPager,
            DxPaging,
            DxColumn,
            DxExport,
            AccountsPayableForm,
            AccountsPayableHistory
        },
        props: ['win'],
        data() {
            return {
                AccountData: [],
                panel: 0,
                tab: null,
                key: 0,   
                AccountDetails: {},
                addPayment: false,
                shwoHistory: false,
                Currency: JSON.parse(localStorage.getItem('branch')).Currency
            }
            
        },
        computed:{
          
        },
        mounted() {
            this.getAcountsPayable()
        },
        methods: {
            handleModalHiddenHistory(){
                this.$refs.arhModal.destroyChildComponent();
            },
            getAcountsPayable(){
                this.$API.providers.getAcountsPayable().then(response => {
                    this.AccountData = this.handleFormatdata(response)
                    this.filterId()
                })
            },
            reloadData(){
                this.getAcountsPayable()
                this.addPayment = false
            },
            filterId() {
                const id = this.win.meta?.provider.ID_Provider;
                if (id !== undefined) {
                    const filteredData = this.AccountData.find(item => item.ID_Provider === id);
                    this.AccountData = filteredData ? [filteredData] : [];
                }
            },
            handleFormatdata(data){
                if (data.length === 0 || data === undefined) {
                    return []
                }
                return data.map(item => {
                    return {
                        ...item,
                        TotalCanceledShow: this.Currency + " " + item.TotalCanceled.toFixed(2),
                        TotalDebtShow: this.Currency + " " + item.TotalDebt.toFixed(2),
                    }
                })
            },
        },

    }
</script>

<style scoped>

</style>